<template>
    <div class="main">
        <div class="top-box">
            <img class="top-img" src="../../assets/image/logo.png"/>
        </div>
        <div class="content-box">
            <div class="top-title-box">
                <div class="top-title">{{query.jobName}}</div>
                <div class="position-info">
                    <div>{{query.provinceName}}/{{query.cityName}}</div>
                    <div>薪酬：<span class="position-info-num">{{query.salaryMin/1000}}-{{query.salaryMax/1000}}k</span></div>
                </div>
            </div>
            <div class="position-show-box">
                <div class="position-show-title">
                    <span class="position-show-title-icon">.</span>
                    <span class="position-show-title-text">职位描述</span>
                </div>
                <div class="position-content-box">{{nowData.jobIntro}}</div>
                <div class="position-show-title">
                    <span class="position-show-title-icon">.</span>
                    <span class="position-show-title-text">职位要求</span>
                </div>
                <div class="position-content-box">{{nowData.jobRequire}}</div>
                <div class="button-box">
                    <!--<div class="left-button">分享</div>-->
                    <div @click="toList" class="right-button">投递</div>
                </div>
            </div>
            <div class="bottom-blank"></div>
        </div>
        <div class="bottom-blank"></div>
    </div>
</template>

<script>
    import { getTaskInfo } from '@/api/service'
    import { Toast } from 'vant';
    export default {
        name: 'Show',
        components: {
        },
        data() {
            return {
                userId: '', // 用户id
                nowUserInfo: {}, // 当前用户信息
                query: '',
                nowData: ''
            }},
        created() {
            const query = this.$route.query
            if(!query.taskId){
                Toast('参数缺失')
            }
            if(!query.id){
                Toast('参数缺失')
            }
            this.query = query
            this.getAllParam()
        },
        mounted:function(){

        },
        methods: {
            // 获取详情
            getAllParam() {
                const postData = {}
                postData.jobId = this.query.id
                postData.taskId = this.query.taskId
                getTaskInfo(postData).then((response) => {
                    console.log(response)
                    this.nowData = response.data
                })
            },
            // 跳转到岗位列表
            toList() {
                this.$router.push({ path: '/submit', query: this.query})
            }
        }
    }
</script>

<style scoped>
    .main{
        width: 100vw;
        min-height: 100vh;
        background-color:#1243D5;
        background: url('../../assets/image/bj2.jpg')  no-repeat center center fixed ;
        background-size: cover;
        box-sizing: border-box;
        position: relative;
    }
    .top-box{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-top: 20px;
    }
    .top-img{
        width: 90px;
        height: 25px;
    }
    .content-box{
        background-color: #ffffff;
        border-radius: 10px;
        width: calc(100vw - 80px);
        margin: 20px 20px 0 20px;
        padding: 20px;
    }
    .bottom-blank{
        width: 100%;
        height: 30px;
    }
    .top-title{
        height: 22px;
        line-height: 22px;
        font-weight: bold;
        font-size: 16px;
    }
    .position-info{
        display: flex;
        justify-content: space-between;
        color: #606166;
        font-size: 13px;
        margin-top: 10px;
    }
    .position-info-num{
        color: #F98148 ;
    }
    .top-title-box{
        padding-bottom: 20px;
        border-bottom: 1px solid #E5E5E5;
    }
    .position-show-title-icon{
        width: 3px;
        height: 12px;
        background: #2586FF;
        color: #2586FF;
        margin-right: 10px;
    }
    .position-show-title-text{
        font-size: 16px;
        font-weight: bold;
    }
    .position-show-title{
        height: 50px;
        line-height: 50px;
    }
    .position-content-box{
        font-size: 14px;
        color: #606166;
        line-height: 28px;
        white-space: pre-wrap;
        word-break: break-all;
    }
    .button-box{
        display: flex;
        justify-content: space-around;
        margin-top: 30px;
    }
    .left-button{
        width: 108px;
        height: 36px;
        line-height: 36px;
        color: #ffffff;
        font-size: 16px;
        background: linear-gradient(136deg, #20DE7B 0%, #07C160 100%);
        border-radius: 4px;
        text-align: center;
    }
    .right-button{
        width: 108px;
        height: 36px;
        line-height: 36px;
        color: #ffffff;
        font-size: 16px;
        background: linear-gradient(136deg, #5DA3FA 0%, #2586FF 100%);
        border-radius: 4px;
        text-align: center;
    }
</style>
